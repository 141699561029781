import React from 'react';
import { StatusBar } from 'react-native';
import { NavigationContainer } from '@react-navigation/native';
import { createStackNavigator } from '@react-navigation/stack';
import { withAdvanceComponents } from 'advance-components';
import HomeScreen from './src/screens/home-screen';
import AdvanceBreakdownScreen from './src/screens/advance-breakdown-screen';
import AdvanceDetailsHeaderScreen from './src/screens/advance-details-header-screen';
import AlertScreen from './src/screens/alert-screen';
import BadgeScreen from './src/screens/badge-screen';
import BreadcrumbsScreen from './src/screens/breadcrumbs-screen';
import BottomSheetScreen from './src/screens/bottom-sheet-screen';
import BreakdownRowScreen from './src/screens/breakdown-row-screen';
import ButtonScreen from './src/screens/button-screen';
import CameraFrameScreen from './src/screens/camera-frame-screen';
import CameraScreen from './src/screens/camera-screen';
import CheckboxScreen from './src/screens/checkbox-screen';
import ColorsScreen from './src/screens/colors-screen';
import ContentCardScreen from './src/screens/content-card-screen';
import ContentGroupScreen from './src/screens/content-group-screen';
import CreditLineWidgetsScreen from './src/screens/credit-line-widgets-screen';
import DatePickerScreen from './src/screens/date-picker-screen';
import DividerLineScreen from './src/screens/divider-line-screen';
import DocumentLinkScreen from './src/screens/document-link-screen';
import DocumentRowScreen from './src/screens/document-row-screen';
import GapScreen from './src/screens/gap-screen';
import HeadingGroupScreen from './src/screens/heading-group-screen';
import HighlightedContentScreen from './src/screens/highlighted-content-screen';
import IconScreen from './src/screens/icon-screen';
import IllustrationsScreen from './src/screens/illustrations-screen';
import ImagesScreen from './src/screens/images-screen';
import InputScreen from './src/screens/input-screen';
import InstructionsScreen from './src/screens/instructions-screen';
import KycRequirementsScreen from './src/screens/kyc-requirements-screen';
import LabelDataPairScreen from './src/screens/label-data-pair-screen';
import LayoutTokensScreen from './src/screens/layout-tokens-screen';
import ListItemScreen from './src/screens/list-item-screen';
import LoginPinScreen from './src/screens/login-pin-screen';
import LozengeScreen from './src/screens/lozenge-screen';
import ModalScreen from './src/screens/modal-screen';
import NavigationScreen from './src/screens/navigation-screen';
import NavigationListItemScreen from './src/screens/navigation-list-item-screen';
import NavigationMenuScreen from './src/screens/navigation-menu-screen';
import NotificationRowScreen from './src/screens/notification-row-screen';
import OverlayScreen from './src/screens/overlay-screen';
import PageControlScreen from './src/screens/page-control-screen';
import PageEndScreen from './src/screens/page-end-screen';
import PageHandlerScreen from './src/screens/page-handler-screen';
import PaginationScreen from './src/screens/pagination-screen';
import PanelScreen from './src/screens/panel-screen';
import PaymentScreen from './src/screens/payment-screen';
import PillScreen from './src/screens/pill-screen';
import PinKeypadScreen from './src/screens/pin-keypad-screen';
import ProgressBarScreen from './src/screens/progress-bar-screen';
import ProgressHeaderScreen from './src/screens/progress-header-screen';
import ProgressIndicatorScreen from './src/screens/progress-indicator-screen';
import RadioScreen from './src/screens/radio-screen';
import ReferralsScreen from './src/screens/referrals-screen';
import RequirementsScreen from './src/screens/requirements-screen';
import SectionTitleScreen from './src/screens/section-title-screen';
import ShadowScreen from './src/screens/shadow-screen';
import SnackbarScreen from './src/screens/snackbar-screen';
import SpacerScreen from './src/screens/spacer-screen';
import SpecialCardScreen from './src/screens/special-card-screen';
import StatusScreen from './src/screens/status-screen';
import SystemBarScreen from './src/screens/system-bar-screen';
import SystemBarDarkScreen from './src/screens/system-bar/system-bar-dark-screen';
import SystemBarLightScreen from './src/screens/system-bar/system-bar-light-screen';
import TextGroupScreen from './src/screens/text-group-screen';
import TitleBarScreen from './src/screens/title-bar-screen';
import TitleBarDefaultScreen from './src/screens/title-bar/title-bar-default-screen';
import TitleBarDarkScreen from './src/screens/title-bar/title-bar-dark-screen';
import ToggleScreen from './src/screens/toggle-screen';
import TooltipScreen from './src/screens/tooltip-screen';
import TransactionScreen from './src/screens/transaction-screen';
import TypographyScreen from './src/screens/typography-screen';
import VoucherScreen from './src/screens/voucher-screen';
import WizardHeadersAndFootersScreen from './src/screens/wizard-headers-and-footers-screen';

const Stack = createStackNavigator();

function App() {
  return (
    <>
      <StatusBar barStyle='dark-content' backgroundColor='white' />
      <NavigationContainer>
        <Stack.Navigator>
          <Stack.Screen name='HomeScreen' component={HomeScreen} />
          <Stack.Screen
            name='AdvanceBreakdownScreen'
            component={AdvanceBreakdownScreen}
          />
          <Stack.Screen
            name='AdvanceDetailsHeaderScreen'
            component={AdvanceDetailsHeaderScreen}
          />
          <Stack.Screen name='AlertScreen' component={AlertScreen} />
          <Stack.Screen name='BadgeScreen' component={BadgeScreen} />
          <Stack.Screen
            name='BreadcrumbsScreen'
            component={BreadcrumbsScreen}
          />
          <Stack.Screen
            name='BottomSheetScreen'
            component={BottomSheetScreen}
          />
          <Stack.Screen
            name='BreakdownRowScreen'
            component={BreakdownRowScreen}
          />
          <Stack.Screen name='ButtonScreen' component={ButtonScreen} />
          <Stack.Screen
            name='CameraFrameScreen'
            component={CameraFrameScreen}
          />
          <Stack.Screen name='CameraScreen' component={CameraScreen} />
          <Stack.Screen name='CheckboxScreen' component={CheckboxScreen} />
          <Stack.Screen name='ColorsScreen' component={ColorsScreen} />
          <Stack.Screen
            name='ContentCardScreen'
            component={ContentCardScreen}
          />
          <Stack.Screen
            name='ContentGroupScreen'
            component={ContentGroupScreen}
          />
          <Stack.Screen
            name='CreditLineWidgetsScreen'
            component={CreditLineWidgetsScreen}
          />
          <Stack.Screen name='DatePickerScreen' component={DatePickerScreen} />
          <Stack.Screen
            name='DividerLineScreen'
            component={DividerLineScreen}
          />
          <Stack.Screen
            name='DocumentLinkScreen'
            component={DocumentLinkScreen}
          />
          <Stack.Screen
            name='DocumentRowScreen'
            component={DocumentRowScreen}
          />
          <Stack.Screen name='GapScreen' component={GapScreen} />
          <Stack.Screen
            name='HeadingGroupScreen'
            component={HeadingGroupScreen}
          />
          <Stack.Screen
            name='HighlightedContentScreen'
            component={HighlightedContentScreen}
          />
          <Stack.Screen name='IconScreen' component={IconScreen} />
          <Stack.Screen
            name='IllustrationsScreen'
            component={IllustrationsScreen}
          />
          <Stack.Screen name='ImagesScreen' component={ImagesScreen} />
          <Stack.Screen name='InputScreen' component={InputScreen} />
          <Stack.Screen
            name='InstructionsScreen'
            component={InstructionsScreen}
          />
          <Stack.Screen
            name='KycRequirementsScreen'
            component={KycRequirementsScreen}
          />
          <Stack.Screen
            name='LabelDataPairScreen'
            component={LabelDataPairScreen}
          />
          <Stack.Screen
            name='LayoutTokensScreen'
            component={LayoutTokensScreen}
          />
          <Stack.Screen name='ListItemScreen' component={ListItemScreen} />
          <Stack.Screen name='LoginPinScreen' component={LoginPinScreen} />
          <Stack.Screen name='LozengeScreen' component={LozengeScreen} />
          <Stack.Screen name='ModalScreen' component={ModalScreen} />
          <Stack.Screen name='NavigationScreen' component={NavigationScreen} />
          <Stack.Screen
            name='NavigationListItemScreen'
            component={NavigationListItemScreen}
          />
          <Stack.Screen
            name='NavigationMenuScreen'
            component={NavigationMenuScreen}
          />
          <Stack.Screen
            name='NotificationRowScreen'
            component={NotificationRowScreen}
          />
          <Stack.Screen name='OverlayScreen' component={OverlayScreen} />
          <Stack.Screen
            name='PageControlScreen'
            component={PageControlScreen}
          />
          <Stack.Screen name='PageEndScreen' component={PageEndScreen} />
          <Stack.Screen
            name='PageHandlerScreen'
            component={PageHandlerScreen}
          />
          <Stack.Screen name='PaginationScreen' component={PaginationScreen} />
          <Stack.Screen name='PanelScreen' component={PanelScreen} />
          <Stack.Screen name='PaymentScreen' component={PaymentScreen} />
          <Stack.Screen name='PillScreen' component={PillScreen} />
          <Stack.Screen name='PinKeypadScreen' component={PinKeypadScreen} />
          <Stack.Screen
            name='ProgressBarScreen'
            component={ProgressBarScreen}
          />
          <Stack.Screen
            name='ProgressHeaderScreen'
            component={ProgressHeaderScreen}
          />
          <Stack.Screen
            name='ProgressIndicatorScreen'
            component={ProgressIndicatorScreen}
          />
          <Stack.Screen name='RadioScreen' component={RadioScreen} />
          <Stack.Screen name='ReferralsScreen' component={ReferralsScreen} />
          <Stack.Screen
            name='RequirementsScreen'
            component={RequirementsScreen}
          />
          <Stack.Screen
            name='SectionTitleScreen'
            component={SectionTitleScreen}
          />
          <Stack.Screen name='ShadowScreen' component={ShadowScreen} />
          <Stack.Screen name='SystemBarScreen' component={SystemBarScreen} />
          <Stack.Screen
            name='SystemBarDarkScreen'
            component={SystemBarDarkScreen}
          />
          <Stack.Screen
            name='SystemBarLightScreen'
            component={SystemBarLightScreen}
          />
          <Stack.Screen name='SnackbarScreen' component={SnackbarScreen} />
          <Stack.Screen name='SpacerScreen' component={SpacerScreen} />
          <Stack.Screen
            name='SpecialCardScreen'
            component={SpecialCardScreen}
          />
          <Stack.Screen name='StatusScreen' component={StatusScreen} />
          <Stack.Screen name='TextGroupScreen' component={TextGroupScreen} />
          <Stack.Screen name='TitleBarScreen' component={TitleBarScreen} />
          <Stack.Screen
            name='TitleBarDefaultScreen'
            component={TitleBarDefaultScreen}
          />
          <Stack.Screen
            name='TitleBarDarkScreen'
            component={TitleBarDarkScreen}
          />
          <Stack.Screen name='ToggleScreen' component={ToggleScreen} />
          <Stack.Screen name='TooltipScreen' component={TooltipScreen} />
          <Stack.Screen
            name='TransactionScreen'
            component={TransactionScreen}
          />
          <Stack.Screen name='TypographyScreen' component={TypographyScreen} />
          <Stack.Screen name='VoucherScreen' component={VoucherScreen} />
          <Stack.Screen
            name='WizardHeadersAndFootersScreen'
            component={WizardHeadersAndFootersScreen}
          />
        </Stack.Navigator>
      </NavigationContainer>
    </>
  );
}

export default withAdvanceComponents(App);
