import React, { useLayoutEffect } from 'react';
import { Spacer, Alert, Text } from 'advance-components';
import Container from '../components/container';

export default function AlertScreen({ navigation }) {
  useLayoutEffect(() => {
    navigation.setOptions({
      title: 'Alert',
    });
  }, []);

  return (
    <Container>
      <Alert.Simple type='information'>Simple Alert (Information)</Alert.Simple>
      <Spacer size='f24' height />
      <Alert.Simple type='success'>Simple Alert (Success)</Alert.Simple>
      <Spacer size='f24' height />
      <Alert.Simple type='error'>Simple Alert (Error)</Alert.Simple>
      <Spacer size='f24' height />
      <Alert.Simple type='warning'>Simple Alert (Warning)</Alert.Simple>
      <Spacer size='f24' height />
      <Alert.Simple type='none'>Simple Alert (None)</Alert.Simple>
      <Spacer size='f24' height />
      <Alert.Complex title='Title'>Complex Alert Without Footer</Alert.Complex>
      <Spacer size='f24' height />
      <Alert.Complex
        title='Title'
        footer={() => <Text.TitleBase>Footer</Text.TitleBase>}
      >
        Complex Alert With Footer
      </Alert.Complex>
      <Spacer size='f24' height />
      <Alert.Progress title='50%' progress={50}>
        Progress Alert Without Footer
      </Alert.Progress>
      <Spacer size='f24' height />
      <Alert.Progress
        title='25%'
        footer={() => <Text.TitleBase>Footer</Text.TitleBase>}
        progress={25}
      >
        Progress Alert With Footer
      </Alert.Progress>
    </Container>
  );
}
