import React, { useLayoutEffect } from 'react';
import styled from 'styled-components/native';
import { View } from 'react-native';
import { Radio, Gap, Colors } from 'advance-components';
import Container from '../components/container';

const StyledContainer = styled.View`
  margin-right: auto;
  align-items: flex-start;
  justify-content: center;
  padding: 40px;
`;

export default function RadioScreen({ navigation }) {
  useLayoutEffect(() => {
    navigation.setOptions({
      title: 'Radio',
    });
  }, []);

  const imageStyle = {
    backgroundColor: Colors.neutral70,
  };

  return (
    <Container>
      <StyledContainer>
        <Radio
          option={{
            image: <View style={imageStyle} />,
            subLabel: 'Sub label',
          }}
        />

        <Gap size='f48' height />

        <Radio
          option={{
            image: <View style={imageStyle} />,
            subLabel: 'Sub label',
          }}
          disabled
        />
      </StyledContainer>
    </Container>
  );
}
