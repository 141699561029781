import React, { useLayoutEffect } from 'react';
import { Spacer, ListItem } from 'advance-components';
import Container from '../components/container';
import logo from '../../assets/aa-logo.png';

export default function ListItemScreen({ navigation }) {
  useLayoutEffect(() => {
    navigation.setOptions({
      title: 'List Item',
    });
  }, []);

  return (
    <Container>
      <ListItem type='small'>What is the employer code?</ListItem>
      <Spacer size='f24' height />
      <ListItem>What is the employer code?</ListItem>
      <Spacer size='f24' height />
      <ListItem type='label' description='+63917888231'>
        Mobile Number
      </ListItem>
      <Spacer size='f24' height />
      <ListItem type='label' description='+63917888231' buttonLabel='Change'>
        Mobile Number
      </ListItem>
      <Spacer size='f24' height />
      <ListItem imgSrc={logo}>List Item Name</ListItem>
      <Spacer size='f24' height />
      <ListItem imgSrc={logo} description='Requested on 28 JUL 2020'>
        List Item Name
      </ListItem>
      <Spacer size='f24' height />
    </Container>
  );
}
