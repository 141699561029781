import React, { useLayoutEffect } from 'react';
import { Spacer, Breadcrumb } from 'advance-components';
import Container from '../components/container';

export default function BreadcrumbsScreen({ navigation }) {
  useLayoutEffect(() => {
    navigation.setOptions({
      title: 'Breadcrumbs',
    });
  }, []);

  return (
    <Container>
      <Breadcrumb
        color='normal'
        links={[{ label: 'First Link', onPress: () => {} }]}
      >
        Text
      </Breadcrumb>
      <Spacer size='f24' height />
      <Breadcrumb
        color='normal'
        links={[
          { label: 'First Link', onPress: () => {} },
          { label: 'Second Link', onPress: () => {} },
        ]}
      >
        Text
      </Breadcrumb>
      <Spacer size='f24' height />
      <Breadcrumb
        color='white'
        links={[{ label: 'First Link', onPress: () => {} }]}
      >
        Text
      </Breadcrumb>
      <Spacer size='f24' height />
      <Breadcrumb
        color='white'
        links={[
          { label: 'First Link', onPress: () => {} },
          { label: 'Second Link', onPress: () => {} },
        ]}
      >
        Text
      </Breadcrumb>
    </Container>
  );
}
