import React, { useLayoutEffect } from 'react';
import { Spacer, DividerLine, Transaction } from 'advance-components';
import Container from '../components/container';

export default function TranactionScreen({ navigation }) {
  useLayoutEffect(() => {
    navigation.setOptions({
      title: 'Transaction',
    });
  }, []);

  return (
    <Container>
      <Transaction.List
        list={[
          {
            icon: 'Medicine',
            amount: 'P1000',
            purpose: 'Medicine',
            referenceNo: '420QIJQK69',
            status: 'disbursed',
            onPress: () => {},
          },
          {
            icon: 'Medicine',
            amount: 'P1000',
            purpose: 'Medicine',
            referenceNo: '420QIJQK69',
            status: 'processing',
            onPress: () => {},
          },
          {
            icon: 'Medicine',
            amount: 'P1000',
            purpose: 'Medicine',
            referenceNo: '420QIJQK69',
            status: 'none',
            onPress: () => {},
          },
          {
            icon: 'purpose icon',
            pupose: 'Medicine',
            referenceNo: '420QIJQK69',
            status: 'none',
            onPress: () => {},
          },
        ]}
      />
      <Spacer size='f12' height />
      <DividerLine />
      <Spacer size='f12' height />
      <Transaction.List
        list={[
          {
            icon: 'Medicine',
            amount: 'P1000',
            purpose: 'Medicine',
            referenceNo: '420QIJQK69',
            status: 'disbursed',
            onPress: () => {},
          },
          {
            icon: 'Medicine',
            amount: 'P1000',
            purpose: 'Medicine',
            referenceNo: '420QIJQK69',
            status: 'processing',
            onPress: () => {},
          },
          {
            icon: 'Medicine',
            amount: 'P1000',
            purpose: 'Medicine',
            referenceNo: '420QIJQK69',
            status: 'none',
            onPress: () => {},
          },
          {
            icon: 'purpose icon',
            pupose: 'Medicine',
            referenceNo: '420QIJQK69',
            status: 'none',
            onPress: () => {},
          },
        ]}
      />
      <Spacer size='f12' height />
      <DividerLine />
      <Spacer size='f12' height />
      <Transaction.Breakdown status='error' />
      <Spacer size='f12' height />
      <DividerLine />
      <Spacer size='f12' height />
      <Transaction.Breakdown status='success' />
    </Container>
  );
}
