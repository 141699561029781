import React, { useLayoutEffect } from 'react';
import { Spacer, Badge } from 'advance-components';
import Container from '../components/container';

export default function BadgeScreen({ navigation }) {
  useLayoutEffect(() => {
    navigation.setOptions({
      title: 'Badge',
    });
  }, []);

  return (
    <Container>
      <Badge type='information'>Information</Badge>
      <Spacer size='f24' height />
      <Badge type='success'>Success</Badge>
      <Spacer size='f24' height />
      <Badge type='warning'>Warning</Badge>
      <Spacer size='f24' height />
      <Badge type='error'>Error</Badge>
    </Container>
  );
}
