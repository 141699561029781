import React, { useLayoutEffect, useState } from 'react';
import { Input, Gap, Text, Colors, DividerLine } from 'advance-components';
import Container from '../components/container';
import Row from '../components/row';

const dropDownItems = Array.from({ length: 10 }, (_, index) => index + 1);

export default function InputScreen({ navigation }) {
  useLayoutEffect(() => {
    navigation.setOptions({
      title: 'Input',
    });
  }, []);

  const [pin, setPin] = useState('');
  const [text, setText] = useState({
    default: '',
    success: '',
    error: '',
  });
  const [error, setError] = useState(false);
  const styles = {
    container: {
      padding: 40,
    },
    icon: {
      default: {
        margin: -2,
        cursor: 'default',
      },
      disabled: {
        color: Colors.neutral70,
        pointerEvents: 'none',
      },
    },
    fieldContainer: {
      backgroundColor: Colors.neutral100,
      maxWidth: 600,
      borderRadius: Gap.sizes.f8,
    },
  };

  return (
    <Container style={styles.container}>
      <Text.TitleBase>Text field</Text.TitleBase>
      <Gap size='f48' height />

      <Container style={styles.fieldContainer}>
        <Input.TextField
          label='Field label'
          helper='Helper text'
          lefticon='account-stroke'
          righticon='correct-solid'
          defaultValue={text.default}
          value={text.default}
          onChangeText={(value) =>
            setText({
              default: value,
              success: text.success,
              error: text.error,
            })
          }
          placeholder='Sample content here'
        />

        <Gap size='f24' height />

        <Input.TextField
          label='Field label'
          helper='Helper text'
          lefticon='account-stroke'
          righticon='correct-solid'
          defaultValue={text.error}
          value={text.error}
          onChangeText={(value) =>
            setText({
              default: text.default,
              success: text.success,
              error: value,
            })
          }
          placeholder='Sample content here'
          error
        />

        <Gap size='f24' height />

        <Input.TextField
          label='Field label'
          helper='Helper text'
          lefticon='account-stroke'
          righticon='correct-solid'
          defaultValue={text.success}
          value={text.success}
          onChangeText={(value) =>
            setText({
              default: text.default,
              success: value,
              error: text.error,
            })
          }
          placeholder='Sample content here'
          success
        />

        <Gap size='f24' height />

        <Input.TextField
          label='Field label'
          helper='Helper text'
          lefticon='account-stroke'
          righticon='correct-solid'
          placeholder='Sample content here'
          disabled
        />
      </Container>

      <Gap size='f96' height />
      <Text.TitleBase>Dropdown</Text.TitleBase>
      <Gap size='f48' height />

      <Input.DropDown
        label='Default field'
        items={dropDownItems.map((number) => {
          const item = `Dropdown Item ${number}`;

          return { label: item, value: item };
        })}
        placeholder='Hint text'
      />

      <Gap size='f24' height />

      <Input.DropDown
        label='Error field label'
        requiredLabel
        items={dropDownItems.map((number) => {
          const item = `Dropdown Item ${number}`;

          return { label: item, value: item };
        })}
        helper='Select an option'
        placeholder='Hint text'
        error
      />

      <Gap size='f24' height />

      <Input.DropDown
        label='Default field'
        items={dropDownItems.map((number) => {
          const item = `Dropdown Item ${number}`;

          return { label: item, value: item };
        })}
        placeholder='Hint text'
        disabled
      />

      <Gap size='f96' height />
      <Text.TitleBase>Text Area</Text.TitleBase>
      <Gap size='f48' height />

      <Input.TextArea
        label='Field label'
        helper='Helper text'
        placeholder='Hint text'
      />

      <Gap size='f24' height />

      <Input.TextArea
        label='Field label'
        helper='Helper text'
        placeholder='Hint text'
        error
      />

      <Gap size='f24' height />

      <Input.TextArea
        label='Field label'
        helper='Helper text'
        placeholder='Hint text'
        disabled
      />

      <Gap size='f96' height />
      <Text.TitleBase>Pin Input</Text.TitleBase>
      <Gap size='f48' height />

      <Text.BodyBase>Regular - Default</Text.BodyBase>
      <Gap size='f16' height />

      <Input.PinInput value={pin} setValue={setPin} />

      <Gap size='f24' height />
      <Text.BodyBase>Regular - Error</Text.BodyBase>
      <Gap size='f16' height />

      <Input.PinInput
        value={pin}
        setValue={setPin}
        onChange={() => setError(true)}
        error={error}
        onCloseTooltip={() => setError(false)}
      />

      <Gap size='f28' height />
      <Text.BodyBase>Small - Default</Text.BodyBase>
      <Gap size='f16' height />

      <Input.PinInput size='small' value={pin} setValue={setPin} />

      <Gap size='f24' height />
      <Text.BodyBase>Small - Error</Text.BodyBase>
      <Gap size='f16' height />

      <Input.PinInput
        size='small'
        value={pin}
        setValue={setPin}
        onChange={() => setError(true)}
        error={error}
        onCloseTooltip={() => setError(false)}
      />

      <Gap size='f96' height />
      <Text.TitleBase>File Upload</Text.TitleBase>
      <Gap size='f48' height />

      <Input.FileUpload label='Basic' />

      <Gap size='f24' height />

      <Input.FileUpload
        hasFile
        fileName='uploading.jpg'
        uploading
        progress={50}
      />

      <Gap size='f24' height />

      <Input.FileUpload hasFile fileName='uploaded.jpg' />

      <Gap size='f24' height />

      <Input.FileUpload hasFile fileName='uploaded.jpg' error />

      <Gap size='f24' height />

      <Input.FileUpload
        hasFile
        fileName='uploaded.jpg'
        label='Multiple'
        multiple
      />

      <Gap size='f24' height />

      <Input.FileUpload label='Disabled' disabled />

      <Gap size='f96' height />
      <DividerLine />
      <Gap size='f48' height />
      <Text.TitleBase>V1 Input</Text.TitleBase>
      <Gap size='f48' height />
      <Text.BodyBase>Radio Button</Text.BodyBase>
      <Gap size='f16' height />
      <Row>
        <Input.RadioButton label='normal' />
        <Input.RadioButton label='checked' checked />
        <Input.RadioButton label='disabled' disabled />
      </Row>
      <Gap size='f16' height />
      <Text.BodyBase>Check Box</Text.BodyBase>
      <Gap size='f16' height />
      <Row>
        <Input.CheckBox label='normal' />
        <Input.CheckBox label='checked' checked />
        <Input.CheckBox label='disabled' disabled />
      </Row>
    </Container>
  );
}
