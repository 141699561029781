import React, { useLayoutEffect } from 'react';
import styled from 'styled-components/native';
import { Button, DividerLine, Spacer, Text, Icon } from 'advance-components';
import Container from '../components/container';
import Row from '../components/row';

const StyledRow = styled.View`
  flex-direction: row;
`;

const FlexRow = styled.View`
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 16;
`;

const StyledColumn = styled.View`
  flex-direction: column;
  gap: 16;
`;

export default function ButtonScreen({ navigation }) {
  useLayoutEffect(() => {
    navigation.setOptions({
      title: 'Button',
    });
  }, []);

  const [counter, setCounter] = React.useState(0);
  return (
    <Container>
      <StyledColumn>
        <Text.BodyBase>Primary / Solid Button</Text.BodyBase>
        <Button>Primary</Button>
        <Button icon='info'>Primary with Leading Icon</Button>
        <Button icon='view-agenda' trailingIcon>
          Primary with Trailing Icon
        </Button>
        <FlexRow>
          <Button
            icon='checkbox-blank'
            iconFamily={Icon.MaterialCommunityIcons}
          >
            Primary - regular
          </Button>
          <Button
            icon='checkbox-blank'
            iconFamily={Icon.MaterialCommunityIcons}
            size='small'
          >
            Primary - small
          </Button>
          <Button
            icon='checkbox-blank'
            iconFamily={Icon.MaterialCommunityIcons}
            size='xs'
          >
            Primary - xs
          </Button>
        </FlexRow>
        <Spacer size='f16' height />
        <DividerLine />
        <Spacer size='f16' height />
      </StyledColumn>

      <StyledColumn>
        <Text.BodyBase>Primary / Hollow Button</Text.BodyBase>
        <Button type='hollow'>Hollow</Button>
        <Button type='hollow' icon='info'>
          Hollow with Leading Icon
        </Button>
        <Button type='hollow' icon='view-agenda' trailingIcon>
          Hollow with Trailing Icon
        </Button>
        <FlexRow>
          <Button
            type='hollow'
            icon='checkbox-blank'
            iconFamily={Icon.MaterialCommunityIcons}
          >
            Hollow - regular
          </Button>
          <Button
            type='hollow'
            size='small'
            icon='checkbox-blank'
            iconFamily={Icon.MaterialCommunityIcons}
          >
            Hollow - small
          </Button>
          <Button
            type='hollow'
            size='xs'
            icon='checkbox-blank'
            iconFamily={Icon.MaterialCommunityIcons}
          >
            Hollow - xs
          </Button>
        </FlexRow>
        <Spacer size='f16' height />
        <DividerLine />
        <Spacer size='f16' height />
      </StyledColumn>

      <StyledColumn>
        <Text.BodyBase>Secondary Button</Text.BodyBase>
        <Button type='secondary'>Secondary</Button>
        <Button type='secondary' icon='info'>
          Secondary with Leading Icon
        </Button>
        <Button type='secondary' icon='view-agenda' trailingIcon>
          Secondary with Trailing Icon
        </Button>
        <FlexRow>
          <Button
            type='secondary'
            icon='checkbox-blank'
            iconFamily={Icon.MaterialCommunityIcons}
          >
            Secondary - regular
          </Button>
          <Button
            type='secondary'
            size='small'
            icon='checkbox-blank'
            iconFamily={Icon.MaterialCommunityIcons}
          >
            Secondary - small
          </Button>
          <Button
            type='secondary'
            size='xs'
            icon='checkbox-blank'
            iconFamily={Icon.MaterialCommunityIcons}
          >
            Secondary - xs
          </Button>
        </FlexRow>
        <Spacer size='f16' height />
        <DividerLine />
        <Spacer size='f16' height />
      </StyledColumn>

      <StyledColumn>
        <Text.BodyBase>Text Only Button</Text.BodyBase>
        <Button type='text'>Text Only</Button>
        <Button type='text' icon='info'>
          Text Only with Leading Icon
        </Button>
        <Button type='text' icon='view-agenda' trailingIcon>
          Text Only with Trailing Icon
        </Button>
        <FlexRow>
          <Button
            type='text'
            icon='checkbox-blank'
            iconFamily={Icon.MaterialCommunityIcons}
          >
            Text Only - regular
          </Button>
          <Button
            type='text'
            size='small'
            icon='checkbox-blank'
            iconFamily={Icon.MaterialCommunityIcons}
          >
            Text Only - small
          </Button>
          <Button
            type='text'
            size='xs'
            icon='checkbox-blank'
            iconFamily={Icon.MaterialCommunityIcons}
          >
            Text Only - xs
          </Button>
        </FlexRow>
        <Spacer size='f16' height />
        <DividerLine />
        <Spacer size='f16' height />
      </StyledColumn>

      <StyledColumn>
        <Text.BodyBase>Disabled Button</Text.BodyBase>
        <Button disabled>Disabled</Button>
        <Button disabled icon='info'>
          Disabled with Leading Icon
        </Button>
        <Button disabled icon='view-agenda' trailingIcon>
          Disabled with Trailing Icon
        </Button>
        <FlexRow>
          <Button
            disabled
            icon='checkbox-blank'
            iconFamily={Icon.MaterialCommunityIcons}
          >
            Disabled - regular
          </Button>
          <Button
            disabled
            size='small'
            icon='checkbox-blank'
            iconFamily={Icon.MaterialCommunityIcons}
          >
            Disabled - small
          </Button>
          <Button
            disabled
            size='xs'
            icon='checkbox-blank'
            iconFamily={Icon.MaterialCommunityIcons}
          >
            Disabled - xs
          </Button>
        </FlexRow>
        <Spacer size='f16' height />
        <DividerLine />
        <Spacer size='f16' height />
      </StyledColumn>
      <StyledColumn>
        <Text.BodyBase>Debounced Button Behavior</Text.BodyBase>
        <FlexRow>
          <Button onPress={() => setCounter(counter + 1)}>
            default with debounce (500ms)
          </Button>
          <Text.BodyBase>Number of clicks: {counter}</Text.BodyBase>
          <Button
            onPress={() => setCounter(counter + 1)}
            debounce={false}
            type='hollow'
          >
            debounce set to false
          </Button>
        </FlexRow>
        <Spacer size='f16' height />
        <DividerLine />
        <Spacer size='f16' height />
      </StyledColumn>

      <Text.BodyBase>V1 Buttons</Text.BodyBase>
      <DividerLine />
      <Spacer size='f16' height />
      <Text.BodyBase>Icon Buttons -- Secondary</Text.BodyBase>
      <Spacer size='f16' height />
      <Row>
        <Button.Icon icon='view-agenda' />
        <Button.Icon disabled icon='view-agenda' />
        <Button.Icon icon='more-horiz' />
        <Button.Icon disabled icon='more-horiz' />
      </Row>
      <Spacer size='f16' height />
      <Text.BodyBase>Icon Buttons -- Tertiary</Text.BodyBase>
      <Spacer size='f16' height />
      <Row>
        <Button.Icon type='tertiary' icon='view-agenda' />
        <Button.Icon type='tertiary' disabled icon='view-agenda' />
        <Button.Icon type='tertiary' icon='more-horiz' />
        <Button.Icon type='tertiary' disabled icon='more-horiz' />
      </Row>
      <Spacer size='f16' height />
      <DividerLine />
      <Spacer size='f16' height />
      <Text.BodyBase>Vertical Buttons</Text.BodyBase>
      <Spacer size='f16' height />
      <Row>
        <Button.Vertical icon='view-agenda'>Sample Text</Button.Vertical>
        <Button.Vertical disabled icon='view-agenda'>
          Text
        </Button.Vertical>
        <Button.Vertical icon='more-horiz'>More</Button.Vertical>
        <Button.Vertical disabled icon='more-horiz'>
          More
        </Button.Vertical>
      </Row>
      <Spacer size='f16' height />
      <DividerLine />
      <Spacer size='f16' height />
      <Text.BodyBase>Tab Buttons</Text.BodyBase>
      <Spacer size='f16' height />
      <StyledRow>
        <Button.Tab>Tab</Button.Tab>
        <Spacer size='f8' width />
        <Button.Tab type='active'>Active Tab</Button.Tab>
        <Spacer size='f8' width />
        <Button.Tab disabled>Disabled Tab</Button.Tab>
      </StyledRow>
      <Spacer size='f16' height />
      <DividerLine />
      <Spacer size='f16' height />
      <Text.BodyBase>Square Buttons</Text.BodyBase>
      <Spacer size='f16' height />
      <Row>
        <Button.Square icon='add-moderator'>Sample</Button.Square>
        <Button.Square icon='add-moderator' type='selected'>
          Sample
        </Button.Square>
        <Button.Square icon='medical-services'>Medicine</Button.Square>
        <Button.Square icon='medical-services' type='selected'>
          Medicine
        </Button.Square>
      </Row>
      <Spacer size='f16' height />
      <DividerLine />
      <Spacer size='f16' height />
      <Text.BodyBase>Rectangular Buttons</Text.BodyBase>
      <Spacer size='f16' height />
      <Button.Rectangle type='initial' headerLeft='1 Payroll'>
        ₱ 0.00
      </Button.Rectangle>
      <Spacer size='f12' height />
      <Button.Rectangle headerLeft='1 Payroll'>₱ 7,650.00</Button.Rectangle>
      <Spacer size='f12' height />
      <Button.Rectangle type='selected' headerLeft='1 Payroll'>
        ₱ 7,650.00
      </Button.Rectangle>
    </Container>
  );
}
