import React, { useLayoutEffect } from 'react';
import styled from 'styled-components/native';
import { Spacer, BottomSheet } from 'advance-components';
import Container from '../components/container';

const StyledRow = styled.View`
  flex-direction: row;
`;
const StyledContainer = styled.View`
  height: 500px;
  background-color: black;
`;

export default function ToggleScreen({ navigation }) {
  useLayoutEffect(() => {
    navigation.setOptions({
      title: 'Bottom Sheet',
    });
  }, []);

  return (
    <Container>
      <StyledRow>
        <StyledContainer>
          <BottomSheet>This is the bottom sheet</BottomSheet>
        </StyledContainer>
        <Spacer size='f24' width />
        <StyledContainer>
          <BottomSheet.AppPicker />
        </StyledContainer>
        <Spacer size='f24' width />
        <StyledContainer>
          <BottomSheet.Tip />
        </StyledContainer>
        <Spacer size='f24' width />
        <StyledContainer>
          <BottomSheet.TextInput />
        </StyledContainer>
      </StyledRow>
    </Container>
  );
}
