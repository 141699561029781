import React, { useLayoutEffect } from 'react';
import { Spacer, Text } from 'advance-components';
import Container from '../components/container';

export default function TypographyScreen({ navigation }) {
  useLayoutEffect(() => {
    navigation.setOptions({
      title: 'Typography',
    });
  }, []);

  return (
    <Container>
      <Text.DisplayH1>H1 Display</Text.DisplayH1>
      <Spacer size='f24' height />
      <Text.DisplayH2>H2 Display</Text.DisplayH2>
      <Spacer size='f24' height />
      <Text.DisplayH3>H3 Display</Text.DisplayH3>
      <Spacer size='f24' height />
      <Text.TitleH4>H4 Header</Text.TitleH4>
      <Spacer size='f24' height />
      <Text.TitleSubheader>Sub-Header</Text.TitleSubheader>
      <Spacer size='f24' height />
      <Text.TitleBase>Title</Text.TitleBase>
      <Spacer size='f24' height />
      <Text.BodyBold>Body Bold</Text.BodyBold>
      <Spacer size='f24' height />
      <Text.BodyBase>Body Text</Text.BodyBase>
      <Spacer size='f24' height />
      <Text.LabelBold>Label Bold</Text.LabelBold>
      <Spacer size='f24' height />
      <Text.LabelBase>Label</Text.LabelBase>
      <Spacer size='f24' height />
    </Container>
  );
}
